<template>
  <div class="row">

    <div class="col-md-4">
      <div class="card">
        <div class="card-header flex-column align-items-start pb-0" style="cursor: pointer;"
          @click="activeTab = 'merchant'">
          <h2 style="margin-bottom: 20px;">
            Profil Toko
          </h2>
          <p>Total Toko:</p>
          <h3>{{ formatNumber(homeData.merchant_count) }}</h3>
          <p>Total Affiliate:</p>
          <h3>{{ formatNumber(homeData.sales_count) }}</h3>
          <br>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card">
        <div class="card-header flex-column align-items-start pb-0" style="cursor: pointer;" @click="activeTab = 'sku'">
          <h2 style="margin-bottom: 20px;">
            SKU Master
          </h2>
          <p>Total SKU Toqoo:</p>
          <h3>{{ formatNumber(homeData.default_sku_count) }}</h3>
          <p>Total SKU Toko:</p>
          <h3>{{ formatNumber(homeData.merchant_sku_count) }}</h3>
          <br>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card">
        <div class="card-header flex-column align-items-start pb-0" style="cursor: pointer;"
          @click="$router.push('/merchant')">
          <h2 style="margin-bottom: 20px;">
            Subscription
          </h2>
          <div class="row">
            <div class="col">
              <p>Total Subs:</p>
              <h3>{{ homeData.subscription_total }}</h3>
              <p>Active:</p>
              <h3>{{ homeData.subscription_active }}</h3>
            </div>
            <div class="col">
              <p>Billed:</p>
              <h3>{{ homeData.subscription_billed }}</h3>
              <p>Deactivate:</p>
              <h3>{{ homeData.subscription_deactivate }}</h3>
            </div>
          </div>

          <br>
        </div>
      </div>
    </div>

    <div v-if="activeTab === 'default'" class="col-md-12">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h2 class="content-header-title">
          Ticket Support
        </h2>
      </div>
      <Ticket :show-search="false" :show-action="false" />
    </div>

    <div v-if="activeTab === 'sku'" class="col-md-12">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h2 class="content-header-title">
          SKU
        </h2>
        <b-button variant="outline-primary" @click="activeTab = 'default'">
          Close
        </b-button>
      </div>

      <b-card no-body class="p-1">
        <div class="row">
          <div class="col-md-12">
            <h3>SKU Toqoo</h3>
          </div>
          <div class="col-md-12">
            <div class="table-responsive-sm">
              <table class="table b-table table-fixed">
                <thead>
                  <tr>
                    <th>SKU Code</th>
                    <th>Name</th>
                    <th>Brand</th>
                    <th>Unit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="isLoading">
                    <td colspan="5" class="text-center">
                      <b-spinner label="Loading..." variant="primary" />
                    </td>
                  </tr>
                  <tr v-for="item in skuDefault.data" v-if="!isLoading" :key="item">
                    <td>{{ item.sku_code || '-' }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.brand != null ? item.brand.name : '-' }}</td>
                    <td>{{ item.uom.name }}</td>
                  </tr>
                  <tr v-if="skuDefault.total == 0 && !isLoading">
                    <td colspan="5" class="text-center">
                      Data is empty.
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="row mt-1">
                <div class="col-6 pl-2">
                  <small>Showing {{ skuDefault.from }} to {{ skuDefault.to }} from {{ skuDefault.total }}</small>
                </div>
                <div class="col-6 pr-2">
                  <pagination :data="skuDefault" :limit="4" align="right" @pagination-change-page="getDataSkuDefault" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>

      <b-card no-body class="p-1">
        <div class="row">
          <div class="col-md-12">
            <h3>SKU Toko</h3>
          </div>
          <div class="col-md-12">
            <div class="table-responsive-sm">
              <table class="table b-table table-fixed">
                <thead>
                  <tr>
                    <th>Toko</th>
                    <th>SKU Code</th>
                    <th>Name</th>
                    <th>Brand</th>
                    <th>Unit</th>
                    <th>SKU Toqoo</th>
                    <th>SKU Toqoo Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="isLoading">
                    <td colspan="5" class="text-center">
                      <b-spinner label="Loading..." variant="primary" />
                    </td>
                  </tr>
                  <tr v-for="item in skuMerchant.data" v-if="!isLoading" :key="item">
                    <td>{{ item.merchant }}</td>
                    <td>{{ item.sku_code || '-' }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.brand != null ? item.brand.name : '-' }}</td>
                    <td>{{ item.uom.name }}</td>
                    <td>{{ item.related_product && item.related_product.sku_code || '-' }}</td>
                    <td>{{ item.related_product && item.related_product.name || '-' }}</td>
                  </tr>
                  <tr v-if="skuMerchant.total == 0 && !isLoading">
                    <td colspan="5" class="text-center">
                      Data is empty.
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="row mt-1">
                <div class="col-6 pl-2">
                  <small>Showing {{ skuMerchant.from }} to {{ skuMerchant.to }} from {{ skuMerchant.total }}</small>
                </div>
                <div class="col-6 pr-2">
                  <pagination :data="skuMerchant" :limit="4" align="right"
                    @pagination-change-page="getDataSkuMerchant" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>

      <!-- <b-card no-body class="p-1">
        <linked-products></linked-products>
      </b-card> -->
    </div>

    <div v-if="activeTab === 'merchant'" class="col-md-12">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h2 class="content-header-title">
          Profil Toko
        </h2>
        <b-button variant="outline-primary" @click="activeTab = 'default'">
          Close
        </b-button>
      </div>
      <b-card>
        <div class="row">
          <div class="col-md-8">
            <h3>Sebaran Wilayah</h3>
            <GmapMap :center="{ lat: -1.269160, lng: 116.825264 }" :zoom="4" map-type-id="terrain"
              style="width: 100%; height: 30rem">
              <GMapCluster :zoom-on-click="true">
                <GmapMarker v-for="(m, index) in locations" :key="index" :position="m.position" :clickable="true"
                  :draggable="true" @click="center = m.position" />
              </GMapCluster>
            </GmapMap>

            <br>

          </div>
          <div class="col-md-4">
            <h3>Daerah Toko Bangunan</h3>
            <table class="table table-bordered table-hovered">
              <tr>
                <th>Kota</th>
                <th>Jumlah Toko</th>
              </tr>
              <tr v-for="(location, index) in homeData.locations" :key="index">
                <td>{{ location.city }}</td>
                <td>{{ location.total }}</td>
              </tr>
            </table>
          </div>

        </div>
      </b-card>

      <b-card no-body class="p-1">
        <div class="row">
          <div class="col-md-10">
            <h3>List Toko Bangunan</h3>
          </div>
          <div class="col-md-2 text-right">
            <b-button variant="primary" size="sm" @click="$router.push('merchant')">
              Detail
            </b-button>
          </div>

          <div class="col-md-12">
            <div class="table-responsive-sm">
              <table class="table b-table table-fixed">
                <thead>
                  <tr>
                    <th>Store Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Join Date</th>
                    <th>User</th>
                    <th>Branch</th>
                    <th>Remaining Renewal Days</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="isLoading">
                    <td colspan="5" class="text-center">
                      <b-spinner label="Loading..." variant="primary" />
                    </td>
                  </tr>
                  <tr v-for="item in merchants.data" v-if="!isLoading" :key="item">
                    <td>{{ item.name || '-' }}</td>
                    <td>{{ item.owner ? item.owner.email || '-' : '-' }}</td>
                    <td>{{ item.owner ? item.owner.phone || '-' : '-' }}</td>
                    <td>{{ item.renewal_status || '-' }}</td>
                    <td>{{ item.register_date || '-' }}</td>
                    <td>{{ item.user_count }}</td>
                    <td>{{ item.branch_count }}</td>
                    <td>{{ item.remaining_renewal_days || 0 }} days</td>
                  </tr>
                  <tr v-if="merchants.total == 0 && !isLoading">
                    <td colspan="5" class="text-center">
                      Data is empty.
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="row mt-1">
                <div class="col-6 pl-2">
                  <small>Showing {{ merchants.from }} to {{ merchants.to }} from {{ merchants.total }}</small>
                </div>
                <div class="col-6 pr-2">
                  <pagination :data="merchants" :limit="4" align="right" @pagination-change-page="getDataMerchant" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>

      <b-card no-body class="p-1">
        <div class="row">
          <div class="col-md-10">
            <h3>List Affiliate</h3>
          </div>
          <div class="col-md-2 text-right">
            <b-button variant="primary" size="sm" @click="$router.push('sales')">
              Detail
            </b-button>
          </div>

          <div class="col-md-12">
            <div class="table-responsive-sm">
              <table class="table b-table table-fixed">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Store Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="isLoading">
                    <td colspan="5" class="text-center">
                      <b-spinner label="Loading..." variant="primary" />
                    </td>
                  </tr>
                  <tr v-for="item in sales.data" v-if="!isLoading" :key="item">
                    <td><img :src="item.photo_url" style="max-width: 50px; margin-right: 10px;">{{ item.name || '-' }}
                    </td>
                    <td>{{ item.phone || '-' }}</td>
                    <td>{{ item.store_count }}</td>
                  </tr>
                  <tr v-if="sales.total == 0 && !isLoading">
                    <td colspan="5" class="text-center">
                      Data is empty.
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="row mt-1">
                <div class="col-6 pl-2">
                  <small>Showing {{ sales.from }} to {{ sales.to }} from {{ sales.total }}</small>
                </div>
                <div class="col-6 pr-2">
                  <pagination :data="sales" :limit="4" align="right" @pagination-change-page="getDataSales" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>

    </div>

  </div>
</template>

<script>
import { BCard, BCardText, BButton } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { gmapApi } from 'vue2-google-maps'
import Ticket from '@/views/ticket/index.vue'
// import LinkedProducts from './Product/LinkedProducts.vue'

export default {
  title() {
    return `Dashboard`
  },
  components: {
    BButton,
    BCard,
    BCardText,
    gmapApi,
    Ticket,
    // LinkedProducts,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      activeTab: 'default',
      homeData: Object,
      locations: [],
      merchants: Object,
      currentPageMerchant: 1,
      sales: Object,
      currentPageSales: 1,
      skuDefault: Object,
      currentPageSkuDefault: 1,
      skuMerchant: Object,
      currentPageSkuMerchant: 1,
    }
  },
  mounted() {
    this.getData()
    this.getDataMerchant(1)
    this.getDataSales(1)
    this.getDataSkuDefault()
    this.getDataSkuMerchant()
  },
  methods: {
    getData() {
      this.$http.get('/super-admin/home')
        .then(response => {
          this.homeData = response.data.data
          this.homeData.locations.forEach(m => {
            this.locations.push({
              position: {
                lat: parseFloat(m.latitude),
                lng: parseFloat(m.longitude),
              },
            })
          })
          this.isLoading = false
        })
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    getDataMerchant(page = 1) {
      this.isLoading = true
      this.currentPageMerchant = page
      const queryParams = { per_page: 10 }
      queryParams.page = this.currentPageMerchant

      this.$http.get('/super-admin/merchant', {
        params: queryParams,
      })
        .then(response => {
          this.merchants = response.data.data
          this.isLoading = false
        })
    },
    getDataSales(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = { per_page: 10 }
      queryParams.page = this.currentPageSales

      this.$http.get('/super-admin/sales', {
        params: queryParams,
      })
        .then(response => {
          this.sales = response.data.data
          this.isLoading = false
        })
    },
    getDataSkuDefault(page = 1) {
      this.isLoading = true
      this.currentPageSkuDefault = page
      const queryParams = { per_page: 10 }
      queryParams.page = this.currentPageSkuDefault
      queryParams.product_type = 'default'

      this.$http.get('/super-admin/product', {
        params: queryParams,
      })
        .then(response => {
          this.skuDefault = response.data.data
          this.isLoading = false
        })
    },
    getDataSkuMerchant(page = 1) {
      this.isLoading = true
      this.currentPageSkuMerchant = page
      const queryParams = { per_page: 10 }
      queryParams.page = this.currentPageSkuMerchant
      queryParams.product_type = 'merchant'

      this.$http.get('/super-admin/product', {
        params: queryParams,
      })
        .then(response => {
          this.skuMerchant = response.data.data
          this.isLoading = false
        })
    },
  },
}
</script>
