<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <input v-model="filter.search" type="text" class="form-control" placeholder="Search...">
        </div>
      </div>
      <div class="col-md-4" />
      <div class="col-md-2">
        <button class="btn btn-primary btn-block" :disabled="isLoadingExort" @click="exportData()">
          <b-spinner v-if="isLoadingExort" label="Loading..." variant="light" small class="ml-1" />
          <span v-else>Export</span>
        </button>
      </div>
      <div class="col-md-2">
        <button v-b-modal.form-modal class="btn btn-primary btn-block" @click="cleanUpForm()">
          Create
        </button>
      </div>
    </div>

    <b-card-code no-body>
      <div class="table-responsive-sm">
        <table class="table b-table table-fixed">
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Store Count</th>
              <th>Status</th>
              <th>Referral Code</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="isLoading">
              <td colspan="5" class="text-center">
                <b-spinner label="Loading..." variant="primary" />
              </td>
            </tr>
            <tr v-for="item in result.data" v-if="!isLoading" :key="item">
              <td><img :src="item.photo_url" style="max-width: 50px; margin-right: 10px;">{{ item.name || '-' }}</td>
              <td>{{ item.phone || '-' }}</td>
              <td>{{ item.store_count }}</td>
              <td>
                <b-badge class="text-capitalize" :variant="type_status[item.sales_information.status]">
                  {{ item.sales_information.status }}
                </b-badge>
              </td>
              <td>{{ item.referral_code || '-' }}</td>
              <td class="text-right">
                <div class="btn-group" role="group" aria-label="Basic example">

                  <button class="btn btn-success btn-sm" style="margin-left: 2px;" data-toggle="tooltip"
                    data-placement="top" title="Detail" @click="detailItem(item)">
                    <feather-icon icon="EyeIcon" />
                  </button>

                  <button class="btn btn-info btn-sm" style="margin-left: 2px;" data-toggle="tooltip"
                    data-placement="top" title="Edit" @click="editItem(item)">
                    <feather-icon icon="EditIcon" />
                  </button>

                  <button class="btn btn-danger btn-sm" style="margin-left: 2px;" data-toggle="tooltip"
                    data-placement="top" title="Delete" @click="deleteItem(item.id)">
                    <feather-icon icon="Trash2Icon" />
                  </button>

                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret
                    v-if="item.sales_information.status === 'pending'">
                    <template v-slot:button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                    </template>
                    <b-dropdown-item @click="handleApproveRejectUser('approve', item.id)">
                      <span>Approve</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="handleApproveRejectUser('reject', item.id)">
                      <span>Reject</span>
                    </b-dropdown-item>
                  </b-dropdown>

                </div>
              </td>
            </tr>
            <tr v-if="result.total == 0 && !isLoading">
              <td colspan="5" class="text-center">
                Data is empty.
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-1">
          <div class="col-6 pl-2">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col-6 pr-2">
            <pagination :data="result" :limit="4" align="right" @pagination-change-page="getData" />
          </div>
        </div>
      </div>
    </b-card-code>

    <!-- Detail Modal -->
    <b-modal id="detail-modal" no-close-on-backdrop size="lg" title="Detail">

      <table v-if="detail != null" class="table table-hover">
        <tr>
          <td>Name</td>
          <td>{{ detail.name }}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{{ detail.email }}</td>
        </tr>
        <tr>
          <td>Phone</td>
          <td>{{ detail.phone }}</td>
        </tr>
        <tr>
          <td>Photo</td>
          <td><img :src="detail.photo_url" style="max-width: 200px;"></td>
        </tr>
        <tr>
          <td>Store Count</td>
          <td>{{ detail.store_count }}</td>
        </tr>
        <tr>
          <td>Bank Name</td>
          <td>{{ detail.sales_information.bank_name }}</td>
        </tr>
        <tr>
          <td>Bank Account Number</td>
          <td>{{ detail.sales_information.bank_account_number }}</td>
        </tr>
        <tr>
          <td>Bank Account Name</td>
          <td>{{ detail.sales_information.bank_account_name }}</td>
        </tr>
      </table>
      <hr class="mt-2">
      <h3>List Toko Affiliate</h3>
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>Toko</th>
            <th>Alamat</th>
            <th>Tanggal Daftar</th>
            <th>Jumlah Cabang</th>
            <th>Jumlah User</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="5" class="text-center">
              <b-spinner label="Loading..." variant="primary" />
            </td>
          </tr>
          <tr v-for="item, index in affiliatedMerchant.data" v-if="!isLoading" :key="`${item} ${index}`">
            <td>{{ item.name || '-' }}</td>
            <td>{{ item.address || '-' }}</td>
            <td>{{ item.register_date || '-' }}</td>
            <td>{{ item.branch_count || '-' }}</td>
            <td>{{ item.user_count || '-' }}</td>
          </tr>
          <tr v-if="affiliatedMerchant.total == 0 && !isLoading">
            <td colspan="5" class="text-center">
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row mt-1">
        <div class="col-6 pl-2">
          <small>Showing {{ affiliatedMerchant.from }} to {{ affiliatedMerchant.to }} from {{ affiliatedMerchant.total
            }}</small>
        </div>
        <div class="col-6 pr-2">
          <pagination :data="affiliatedMerchant" :limit="4" align="right"
            @pagination-change-page="getAffiliateMerchant" />
        </div>
      </div>
    </b-modal>
    <!-- Detail Modal -->

    <!-- Add/Edit Modal -->
    <b-modal id="form-modal" size="lg" :title="editId != null ? 'Edit' : 'Add'">

      <div class="form-group">
        <label for="">Photo: (Optional)</label><br>
        <input id="file" ref="file" type="file" @change="changeImage()">
      </div>

      <div class="form-group">
        <label for="">Name:</label>
        <input v-model="formPayload.name" type="text" class="form-control"
          :class="validations.hasOwnProperty('name') && validations.name.length > 0 ? 'is-invalid' : ''"
          placeholder="Alias...">
        <small v-if="validations.hasOwnProperty('name') && validations.name.length > 0" class="text-danger">
          {{ validations.name.join(', ') }}
        </small>
      </div>

      <div class="form-group">
        <label for="">Email:</label>
        <input v-model="formPayload.email" type="email" class="form-control"
          :class="validations.hasOwnProperty('email') && validations.email.length > 0 ? 'is-invalid' : ''"
          placeholder="Email...">
        <small v-if="validations.hasOwnProperty('email') && validations.email.length > 0" class="text-danger">
          {{ validations.email.join(', ') }}
        </small>
      </div>

      <div class="form-group">
        <label for="">Phone:</label>
        <input v-model="formPayload.phone" type="text" class="form-control"
          :class="validations.hasOwnProperty('phone') && validations.phone.length > 0 ? 'is-invalid' : ''"
          placeholder="Phone...">
        <small v-if="validations.hasOwnProperty('phone') && validations.phone.length > 0" class="text-danger">
          {{ validations.phone.join(', ') }}
        </small>
      </div>

      <div class="form-group">
        <label for="">Password:</label>
        <input v-model="formPayload.password" type="password" class="form-control"
          :class="validations.hasOwnProperty('password') && validations.password.length > 0 ? 'is-invalid' : ''"
          placeholder="Password...">
        <small v-if="validations.hasOwnProperty('password') && validations.password.length > 0" class="text-danger">
          {{ validations.password.join(', ') }}
        </small>
      </div>

      <div class="form-group">
        <label for="">Bank Name:</label>
        <input v-model="formPayload.sales_information.bank_name" type="text" class="form-control"
          :class="validations.hasOwnProperty('sales_information.bank_name') && validations.sales_information.bank_name.length > 0 ? 'is-invalid' : ''"
          placeholder="Bank Name...">
        <small
          v-if="validations.hasOwnProperty('sales_information.bank_name') && validations.sales_information.bank_name.length > 0"
          class="text-danger">
          {{ validations.sales_information.bank_name.join(', ') }}
        </small>
      </div>

      <div class="form-group">
        <label for="">Bank Account Number:</label>
        <input v-model="formPayload.sales_information.bank_account_number" type="text" class="form-control"
          :class="validations.hasOwnProperty('sales_information.bank_account_number') && validations.sales_information.bank_account_number.length > 0 ? 'is-invalid' : ''"
          placeholder="Bank Account Number...">
        <small
          v-if="validations.hasOwnProperty('sales_information.bank_account_number') && validations.sales_information.bank_account_number.length > 0"
          class="text-danger">
          {{ validations.sales_information.bank_account_number.join(', ') }}
        </small>
      </div>

      <div class="form-group">
        <label for="">Bank Account Name:</label>
        <input v-model="formPayload.sales_information.bank_account_name" type="text" class="form-control"
          :class="validations.hasOwnProperty('sales_information.bank_account_name') && validations.sales_information.bank_account_name.length > 0 ? 'is-invalid' : ''"
          placeholder="Bank Account Name...">
        <small
          v-if="validations.hasOwnProperty('sales_information.bank_account_name') && validations.sales_information.bank_account_name.length > 0"
          class="text-danger">
          {{ validations.sales_information.bank_account_name.join(', ') }}
        </small>
      </div>

      <template #modal-footer="{ }">
        <div v-if="isLoading">
          <br>
          <b-spinner class="mb-2" variant="primary" /><br>
        </div>
        <b-button v-if="editId == null" variant="success" :disabled="isLoading" @click="createItem()">
          Save Item
        </b-button>
        <b-button v-else variant="success" :disabled="isLoading" @click="updateItem()">
          Save Item
        </b-button>
      </template>
    </b-modal>
    <!-- Add/Edit Modal -->

  </div>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner,
  BFormGroup, BTabs, BTab, BNavItem, BBadge,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import FileSaver from 'file-saver'
import axios from 'axios'
import vSelect from 'vue-select'

import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import { password } from '@/@core/utils/validations/validations'

Vue.use(VuejsDialog)

export default {
  title() {
    return 'Affiliate'
  },
  components: {
    BFormGroup,
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BTabs,
    BTab,
    BNavItem,
    BSpinner,
    vSelect,
    BBadge,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: Object,
      isLoading: false,
      isLoadingImport: false,
      isLoadingExort: false,
      formPayload: {
        name: '',
        email: '',
        phone: '',
        password: null,
        photo: '',
        sales_information: {
          bank_name: '',
          bank_account_number: '',
          bank_account_name: '',
        },
      },
      detail: null,
      editId: null,
      filter: {
        search: '',
      },
      importPayload: {
        file: null,
      },
      formData: {},
      validations: {},
      type_status: {
        success: 'primary',
        pending: 'warning',
        rejected: 'danger'
      },
      affiliatedMerchant: [],
      selected_affiliate_id: null,
      currentPageAffiliatedMerchant: 1
    }
  },
  created() {
    this.getData()
  },
  mounted() {

  },
  methods: {
    changeImage() {
      // eslint-disable-next-line prefer-destructuring
      this.formPayload.photo = this.$refs.file.files[0]
    },
    detailItem(item) {
      this.detail = item
      this.selected_affiliate_id = item.id
      this.getAffiliateMerchant()
    },
    async getAffiliateMerchant(page = 1) {
      this.currentPageAffiliatedMerchant = page
      // this.isLoading = true
      const queryParams = {
        affiliate_id: this.selected_affiliate_id
      }
      queryParams.page = this.currentPageAffiliatedMerchant

      try {
        const response = await this.$http.get(`/super-admin/merchant`, {
          params: queryParams,
        })
        console.log(response.data.data, 'response');
        this.affiliatedMerchant = response.data.data
        this.$bvModal.show('detail-modal')
      } catch (error) {
        console.log(error)
      } finally {
        // this.isLoading = false
      }

    },
    exportData(type = 'excel') {
      this.isLoadingExort = true

      const context = this
      const axiosSource = axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel, msg: 'Loading...' }

      this.$http.get('super-admin/sales/export', {
        responseType: type == 'view' ? '' : 'blob',
        params: this.filter,
        cancelToken: axiosSource.token,
      }).then(response => {
        var timestamp = Math.floor(Date.now() / 1000)
        if (type == 'view') {
          context.contentHtml = response.data.data.html
        } else {
          var timestamp = Math.floor(Date.now() / 1000)
          if (type == 'excel') {
            var fileName = `export-${timestamp}.xlsx`
            FileSaver.saveAs(response.data, fileName)
          }

          if (type == 'pdf') {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            var fileName = `export-${timestamp}.pdf`
            FileSaver.saveAs(blob, fileName)
          }
        }

        context.isLoading = false
        context.isLoadingExort = false
      }).catch(error => {
        context.isLoading = false
        context.isLoadingExort = false

        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)
        }
      })
    },
    cleanUpForm() {
      this.editId = null
      this.formPayload = {
        name: '',
        email: '',
        phone: '',
        password: null,
        photo: '',
        sales_information: {
          bank_name: '',
          bank_account_number: '',
          bank_account_name: '',
        },
      }
    },
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = this.currentPage

      this.$http.get('/super-admin/sales', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    createItem() {
      const form = this.preparePayload()

      this.$http.post('/super-admin/sales', form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Item successfully created')
          this.cleanUpForm()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    updateItem() {
      const form = this.preparePayload()
      form.append('_method', 'PATCH')

      this.$http.post(`/super-admin/sales/${this.editId}`, form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Item successfully updated!')
          this.cleanUpForm()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    preparePayload() {
      const form = new FormData()

      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          // Handle if this.formPayload[key] is array
          form.append(key, this.formPayload[key])
        }
      }

      form.append('sales_information[bank_name]', this.formPayload.sales_information.bank_name)
      form.append('sales_information[bank_account_number]', this.formPayload.sales_information.bank_account_number)
      form.append('sales_information[bank_account_name]', this.formPayload.sales_information.bank_account_name)

      return form
    },
    editItem(item) {
      this.editId = item.id
      this.formPayload = {
        name: item.name,
        email: item.email,
        phone: item.phone,
        photo_url: item.photo_url,
        sales_information: {
          bank_name: item.sales_information.bank_name,
          bank_account_number: item.sales_information.bank_account_number,
          bank_account_name: item.sales_information.bank_account_name,
        },
      }

      this.$bvModal.show('form-modal')
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this item?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`super-admin/sales/${id}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Item successfully deleted',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    handleApproveRejectUser(type, uuid) {
      this.$swal({
        title: 'Are you sure?',
        text: `Are you sure to ${type} this user?`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post(`super-admin/sales/${uuid}/${type}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: `User successfully ${type}`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
