<template>
  <div class="row">
    <div class="col-md-12">
      <b-card
        no-body
        class="p-1"
      >

        <div class="row">

          <div class="col-6">
            <label for="">Search Toko:</label>
            <v-select
              v-model="filter.merchant_id"
              :options="merchants"
              :reduce="item => item.uuid"
              label="name"
              placeholder="Type to search Toko..."
              @search="onSearchMerchant"
            >
              <template slot="no-options">
                Type here to search merchant...
              </template>
            </v-select>
          </div>

          <div
            v-if="filter.merchant_id != null"
            class="col-5"
          >
            <label for="">Search SKU:</label>
            <div class="form-group">
              <input
                v-model="filter.search"
                type="text"
                class="form-control"
                placeholder="Search..."
              >
            </div>
          </div>

          <div
            v-if="filter.merchant_id != null && merchantProducts.length > 0"
            class="col-6 mb-1"
          >
            <strong>SKU Toko:</strong>
          </div>
          <div
            v-if="filter.merchant_id != null && merchantProducts.length > 0"
            class="col-6 mb-1"
          >
            <strong>SKU Toqoo:</strong>
          </div>

          <div
            v-if="filter.merchant_id != null && merchantProducts.length == 0 && !isLoadingLoadMore && filter.search == ''"
            class="col-12"
          >
            <b-alert
              show
              variant="info"
              class="p-1 mt-1"
            >
              Produk pada Toko tidak ditemukan atau semua produk sudah dihubungkan dengan produk Toqoo.
            </b-alert>
          </div>

          <template
            v-for="(merchantProduct, key) in merchantProducts"
            v-if="filter.merchant_id != null && merchantProducts.length > 0"
          >
            <div class="col-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  :value="merchantProduct.name"
                  disabled
                >
              </div>
            </div>
            <div class="col-6">
              <v-select
                v-model="selectPayload.default_product_uuids[merchantProduct.uuid]"
                :options="defaultProducts"
                :reduce="item => item.uuid"
                label="name"
                placeholder="Type to search SKU..."
                @input="changeProduct(merchantProduct.uuid, $event)"
                @search="onSearchDefaultProduct"
              >
                <template slot="no-options">
                  Type here to search item...
                </template>
              </v-select>
            </div>
          </template>

          <div
            v-if="filter.merchant_id != null && merchantProducts.length > 0"
            class="col-6"
          >
            <b-button
              class="btn-info"
              :disabled="isLoadingLoadMore"
              @click="getData(currentPage + 1)"
            >
              {{ isLoadingLoadMore ? 'Loading...' : 'Load More' }}
            </b-button>
          </div>
          <div
            v-if="filter.merchant_id != nul && merchantProducts.length > 0"
            class="col-6 text-right"
          >
            <b-button
              class="btn-success"
              :disabled="isLoadingSave"
              @click="adjustSku()"
            >
              {{ isLoadingSave ? 'Saving...' : 'Adjust SKU' }}
            </b-button>
          </div>

        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BButton, BAlert } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  title() {
    return 'SKU Adjustment'
  },
  components: {
    BCard,
    BButton,
    vSelect,
    BAlert,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      filter: {
        search: '',
        merchant_id: null,
      },
      selectPayload: {
        merchant_product_uuids: [],
        default_product_uuids: [],
      },
      formPayload: {
        merchant_product_uuids: [],
        default_product_uuids: [],
      },
      currentPage: 1,
      isLoadingLoadMore: false,
      isLoadingSave: false,
      merchantProducts: [],
      defaultProducts: [],
      merchants: [],
    }
  },
  mounted() {
    this.getData(1)

    // Load default merchant
    this.$http.get('super-admin/merchant?per_page=50')
      .then(response => {
        response.data.data.data.forEach(merchant => {
          const isExists = this.merchants.find(item => item.uuid === merchant.uuid)
          if (isExists === undefined) {
            this.merchants.push({
              uuid: merchant.uuid,
              name: merchant.name,
            })
          }
        })
      })
      .catch(error => {
        console.error(error)
      })
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData(1)
      }, 300),
      deep: true,
    },
  },
  methods: {
    onSearchMerchant(search, loading) {
      if (search.length) {
        loading(true)
        this.searchMerchant(loading, search, this)
      }
    },
    searchMerchant: _.debounce((loading, search, vm) => {
      vm.$http.get(`super-admin/merchant?per_page=10&search=${encodeURI(search)}`)
        .then(response => {
          response.data.data.data.forEach(merchant => {
            const isExists = vm.merchants.find(item => item.uuid === merchant.uuid)
            if (isExists === undefined) {
              vm.merchants.push({
                uuid: merchant.uuid,
                name: merchant.name,
              })
            }
          })
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    onSearchDefaultProduct(search, loading) {
      if (search.length) {
        loading(true)
        this.searchDefaultProduct(loading, search, this)
      }
    },
    searchDefaultProduct: _.debounce((loading, search, vm) => {
      vm.$http.get(`super-admin/product?per_page=10&product_type=default&search=${encodeURI(search)}`)
        .then(response => {
          // Check is uuid is exists on defaultProducts
          response.data.data.data.forEach(product => {
            const isExists = vm.defaultProducts.find(item => item.uuid === product.uuid)
            if (isExists === undefined) {
              vm.defaultProducts.push({
                uuid: product.uuid,
                name: product.name,
              })
            }
          })
        })
        .catch(error => {
          console.error(error)
        })
      loading(false)
    }, 300),
    getData(currentPage = 1) {
      this.currentPage = currentPage
      this.isLoadingLoadMore = true

      const queryParams = {
        product_type: 'merchant',
        per_page: 10,
        has_related: 0,
        page: currentPage,
        search: this.filter.search,
        merchant_uuid: this.filter.merchant_id,
      }

      if (currentPage == 1) {
        this.merchantProducts = []
      }

      this.$http.get('/super-admin/product', {
        params: queryParams,
      })
        .then(response => {
          response.data.data.data.forEach(product => {
            this.merchantProducts.push(product)
            // this.selectPayload.merchant_product_uuids.push(product.uuid)
            // this.selectPayload.default_product_uuids.push({
            //   [product.uuid]: null,
            // })
          })
          this.isLoadingLoadMore = false
        })
    },
    changeProduct(merchantProductUuid, defaultProduct) {
      this.formPayload.merchant_product_uuids.push(merchantProductUuid)
      this.formPayload.default_product_uuids.push(defaultProduct)
    },
    adjustSku() {
      this.isLoadingSave = true

      console.log(this.formPayload.merchant_product_uuids)
      console.log(this.formPayload.default_product_uuids)

      const form = new FormData()
      if (Array.isArray(this.formPayload.merchant_product_uuids)) {
        this.formPayload.merchant_product_uuids.forEach((merchantUuid, index) => {
          form.append(`merchant_product_uuids[${index}]`, merchantUuid)
        })
      }

      if (Array.isArray(this.formPayload.default_product_uuids)) {
        this.formPayload.default_product_uuids.forEach((defaultUuid, index) => {
          form.append(`default_product_uuids[${index}]`, defaultUuid)
        })
      }

      this.$http.post('/super-admin/product/bulk-link', form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.isLoadingSave = false
          successNotification(this, 'Yeay!', 'SKU has been adjusted successfully.')

          this.merchantProducts = []
          this.defaultProducts = []
          this.getData(1)
        })
        .catch(error => {
          this.isLoadingSave = false
          errorNotification(this, 'Oops!', 'Failed to adjust SKU.')
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
