import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        guest: true,
        layout: 'full',
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        auth: true,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products',
      name: 'products.index',
      component: () => import('@/views/Product/SkuMaster.vue'),
      meta: {
        auth: true,
        pageTitle: 'SKU Master',
        breadcrumb: [
          {
            text: 'Products',
            active: true,
          },
          {
            text: 'SKU Master',
            active: true,
          },
        ],
      },
    },
    {
      path: '/categories',
      name: 'categories.index',
      component: () => import('@/views/Product/Item.vue'),
      meta: {
        auth: true,
        pageTitle: 'Categories',
        breadcrumb: [
          {
            text: 'Products',
            active: true,
          },
          {
            text: 'Categories',
            active: true,
          },
        ],
      },
    },
    {
      path: '/brand',
      name: 'brand.index',
      component: () => import('@/views/Product/Brand.vue'),
      meta: {
        auth: true,
        pageTitle: 'Brand',
        breadcrumb: [
          {
            text: 'Products',
            active: true,
          },
          {
            text: 'Brand',
            active: true,
          },
        ],
      },
    },
    {
      path: '/unit',
      name: 'unit.index',
      component: () => import('@/views/Product/Unit.vue'),
      meta: {
        auth: true,
        pageTitle: 'Brand',
        breadcrumb: [
          {
            text: 'Products',
            active: true,
          },
          {
            text: 'Unit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/item',
      name: 'item.index',
      component: () => import('@/views/Product/Item.vue'),
      meta: {
        auth: true,
        pageTitle: 'Category',
        breadcrumb: [
          {
            text: 'Products',
            active: true,
          },
          {
            text: 'Category',
            active: true,
          },
        ],
      },
    },
    {
      path: '/variation',
      name: 'variation.index',
      component: () => import('@/views/Product/Variation.vue'),
      meta: {
        auth: true,
        pageTitle: 'Variation',
        breadcrumb: [
          {
            text: 'Products',
            active: true,
          },
          {
            text: 'Variation',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sku-adjustment',
      name: 'skuadjustment',
      component: () => import('@/views/Product/SKUAdjustment.vue'),
      meta: {
        auth: true,
        pageTitle: 'SKU Adjustment',
        breadcrumb: [
          {
            text: 'Products',
            active: true,
          },
          {
            text: 'SKU Adjustment',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/linked-products',
    //   name: 'linkedproducts',
    //   component: () => import('@/views/Product/LinkedProducts.vue'),
    //   meta: {
    //     auth: true,
    //     pageTitle: 'Linked Products',
    //     breadcrumb: [
    //       {
    //         text: 'Products',
    //         active: true,
    //       },
    //       {
    //         text: 'Linked Products',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/merchant',
      name: 'merchant.index',
      component: () => import('@/views/Merchant.vue'),
      meta: {
        auth: true,
        pageTitle: 'Subscription',
        breadcrumb: [
          {
            text: 'Toko',
            active: true,
          },
          {
            text: 'Subscription',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sales',
      name: 'sales.index',
      component: () => import('@/views/Sales.vue'),
      meta: {
        auth: true,
        pageTitle: 'Affiliate',
        breadcrumb: [
          {
            text: 'Affiliate',
            active: true,
          }
        ],
      },
    },
    {
      path: '/withdraw',
      name: 'withdraw.index',
      component: () => import('@/views/Withdraw.vue'),
      meta: {
        auth: true,
        pageTitle: 'Affiliate Withdraw',
        breadcrumb: [
          {
            text: 'Affiliate',
            active: true,
          },
          {
            text: 'Withdraw',
            active: true,
          }
        ],
      },
    },
    {
      path: '/image',
      name: 'image.index',
      component: () => import('@/views/Image.vue'),
      meta: {
        auth: true,
        pageTitle: 'Ads Management',
        breadcrumb: [
          {
            text: 'Image',
            active: true,
          },
          {
            text: 'Ads',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ticket',
      name: 'ticket.index',
      component: () => import('@/views/ticket/index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Ticket Support',
        breadcrumb: [
          {
            text: 'Customer Service',
            active: true,
          },
          {
            text: 'Ticket Support',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ticket/:id',
      name: 'ticket.show',
      component: () => import('@/views/ticket/show.vue'),
      meta: {
        auth: true,
        pageTitle: 'Ticket Support',
        breadcrumb: [
          {
            text: 'Customer Service',
            active: true,
          },
          {
            text: 'Ticket Support',
            active: true,
          },
          {
            text: 'Ticket Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/bonus-affiliate/',
      name: 'bonus.index',
      component: () => import('@/views/bonus-affiliate/index.vue'),
      meta: {
        auth: true,
        pageTitle: 'Bonus Affiliate',
        breadcrumb: [
          {
            text: 'Affiliate',
            active: true,
          },
          {
            text: 'Bonus Affiliate',
            active: true,
          },
        ],
      },
    },
    {
      path: '/import-export-sku-toko/',
      name: 'import-export-sku-toko',
      component: () => import('@/views/Product/ImportExportProduct.vue'),
      meta: {
        auth: true,
        pageTitle: 'Import/Export SKU Toko',
        breadcrumb: [
          {
            text: 'Product',
            active: true,
          },
          {
            text: 'Import/Export SKU Toko',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-403',
      name: 'error-403',
      component: () => import('@/views/error/Error403.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()

  // Redirect if not authenticated
  if (to.matched.some(record => record.meta.auth)) {
    if (!isLoggedIn) {
      next({ name: 'login' })
    } else {
      next();
    }
  } else {
    next();
  }

  // Redirect if authenticated
  if (to.matched.some(record => record.meta.guest) && isLoggedIn) {
    next(getHomeRouteForLoggedInUser());
  }

});

export default router
