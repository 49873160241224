<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div>

    <div
      v-if="showSearch"
      class="row"
    >
      <div class="col-md-4">
        <div class="form-group">
          <input
            v-model="filter.search"
            type="text"
            class="form-control"
            placeholder="Search..."
          >
        </div>
      </div>
      <div class="col-md-6" />
      <div class="col-md-2">
        <button
          class="btn btn-primary btn-block"
          :disabled="isLoadingExport"
          @click="exportData()"
        >
          <b-spinner
            v-if="isLoadingExport"
            label="Loading..."
            variant="light"
            small
            class="ml-1"
          />
          <span v-else>Export</span>
        </button>
      </div>
    </div>

    <b-card-code no-body>
      <div class="table-responsive-sm">
        <table class="table b-table table-fixed">
          <thead>
            <tr>
              <th>No</th>
              <th>Toko - User</th>
              <th>Status</th>
              <th>Subjek</th>
              <th>Tanggal Dibuat</th>
              <th>Terakhir Dibalas</th>
              <th>Kategori</th>
              <th>Prioritas</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-if="isLoading">
              <td
                colspan="7"
                class="text-center"
              >
                <b-spinner
                  label="Loading..."
                  variant="primary"
                />
              </td>
            </tr>
            <tr
              v-for="item in result.data"
              v-if="!isLoading"
              :key="item"
            >
              <td>{{ item.id }}</td>
              <td>{{ item.merchant.name }} - {{ item.user.name }}</td>
              <td style="text-transform: capitalize;">
                <div :class="item.status == 'open' ? 'badge badge-success' : 'badge badge-info'">
                  {{ item.status }}
                </div>
              </td>
              <td>{{ item.subject }}</td>
              <td>{{ item.created_at }}</td>
              <td>{{ item.last_replied_at }}</td>
              <td>{{ item.category || '-' }}</td>
              <td>
                <b-badge :variant="priorityScheme[item.priority]" class="text-capitalize">{{ item.priority }}</b-badge>
              </td>
              <td class="text-right">
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                >

                  <b-dropdown
                    v-if="item.status == 'open'"
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    text="Set Prioritas"
                    variant="info"
                  >
                    <b-dropdown-item @click="setPriority('low', item.uuid)">Low</b-dropdown-item>
                    <b-dropdown-item @click="setPriority('normal', item.uuid)">Normal</b-dropdown-item>
                    <b-dropdown-item @click="setPriority('high', item.uuid)">High</b-dropdown-item>
                  </b-dropdown>
                  
                  <button
                    class="btn btn-success btn-sm"
                    style="margin-left: 2px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    @click="detailTicket(item.uuid)"
                  >
                    Detail
                  </button>

                  <button
                    v-if="showAction && item.status == 'open'"
                    class="btn btn-danger btn-sm"
                    style="margin-left: 2px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Close"
                    @click="closeTicket(item.uuid)"
                  >
                    Close
                  </button>

                </div>
              </td>
            </tr>
            <tr v-if="result.total == 0 && !isLoading">
              <td
                colspan="5"
                class="text-center"
              >
                Data is empty.
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-1">
          <div class="col-6 pl-2">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col-6 pr-2">
            <pagination
              :data="result"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </b-card-code>
  </div>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner,
  BFormGroup, BTabs, BTab, BNavItem, BBadge,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import FileSaver from 'file-saver'
import axios from 'axios'
import vSelect from 'vue-select'

import 'vuejs-dialog/dist/vuejs-dialog.min.css'

Vue.use(VuejsDialog)

export default {
  components: {
    BFormGroup,
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BTabs,
    BTab,
    BNavItem,
    BSpinner,
    vSelect,
    BBadge,
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
  },
  title() {
    return this.showSearch ? 'Ticket Support' : null
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: Object,
      isLoading: false,
      isLoadingImport: false,
      isLoadingExport: false,
      formPayload: {
        title: '',
        image: '',
        merchant_uuid: '',
      },
      detail: null,
      editId: null,
      filter: {
        search: '',
      },
      importPayload: {
        file: null,
      },
      formData: {
        merchants: [],
      },
      validations: {},
      priorityScheme: {
        low: 'success',
        normal: 'warning',
        high: 'danger',
      }
    }
  },
  created() {
  },
  mounted() {
    this.getData()
  },
  methods: {
    exportData(type = 'excel') {
      this.isLoadingExport = true

      const context = this
      const axiosSource = axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel, msg: 'Loading...' }

      this.$http.get('super-admin/ticket/export', {
        responseType: type == 'view' ? '' : 'blob',
        params: this.filter,
        cancelToken: axiosSource.token,
      }).then(response => {
        var timestamp = Math.floor(Date.now() / 1000)
        if (type == 'view') {
          context.contentHtml = response.data.data.html
        } else {
          var timestamp = Math.floor(Date.now() / 1000)
          if (type == 'excel') {
            var fileName = `export-${timestamp}.xlsx`
            FileSaver.saveAs(response.data, fileName)
          }

          if (type == 'pdf') {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            var fileName = `export-${timestamp}.pdf`
            FileSaver.saveAs(blob, fileName)
          }
        }

        context.isLoading = false
        context.isLoadingExport = false
      }).catch(error => {
        context.isLoading = false
        context.isLoadingExport = false

        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)
        }
      })
    },
    setPriority(priority, uuid) {
      const formData = new FormData()
      formData.append('priority', priority)
      this.$http.post(`/super-admin/ticket/${uuid}/priority`, formData)
        .then(response => {
          this.getData(this.currentPage)
        }).catch(error => {
          console.log(error);
        })
    },
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = this.currentPage

      this.$http.get('/super-admin/ticket', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    detailTicket(uuid) {
      this.$router.push({ name: 'ticket.show', params: { id: uuid }, query: { page: this.page || 1 } })
    },
    closeTicket(id) {
      const ctx = this
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apa Anda yakin ingin close ticket ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          ctx.$http.post(`super-admin/ticket/${id}/close`).then(() => {
            ctx.isLoading = false
            ctx.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Tiket berhasil ditutup',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            ctx.getData(1)
          }).catch(err => {
            // eslint-disable-next-line no-console
            console.log(err)
          })
        }
      })
    },
  },
}
</script>

    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    </style>
